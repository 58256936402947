<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-name">
      <h2 class="onboarding-content-title">
        What should
        <span style="display: inline-block">we call you?</span>
      </h2>
      <form-input
        id="saveName"
        v-model="name"
        class="form-input"
        type="text"
        name="name"
      />
    </div>
    <XButton
      type="large"
      style="margin-top: auto"
      @click="$router.push('/onboarding/city')"
    >
      Next
    </XButton>
  </div>
</template>

<script>
import XButton from '../../components/common/global/XButton.vue'

export default {
  name: 'OnboardingName',
  components: { XButton },
  computed: {
    name: {
      get() {
        return this.$store.state.OnboardingModule.firstName
      },
      set(name) {
        this.$store.commit('OnboardingModule/setFirstName', name)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-name {
  align-self: center;
  display: grid;
  gap: 2rem;
}
.form-input {
  background: #ffffff;
  border: 1px solid black;
  border-radius: 9px;
  box-shadow: none;
  font-size: 1.2rem;
  height: 50px;
  &:focus {
    box-shadow: none;
  }
}
</style>
